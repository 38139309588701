<mat-card>
    <span class="message">No tiene ninguna ruta asiganda.</span>
</mat-card>
<br>
<br>
<div class="play">
    <button type=buttion mat-raised-button color="accent" (click)="onRefresh()">
        Actualizar
    </button> 
    <button type=buttion mat-raised-button color="accent" [routerLink]="['/tetris']">
        Esperar
    </button> 
</div>
