import { Component, Input, OnInit } from '@angular/core';

import { RouteLocation } from '@marsol/models/route-location';

@Component({
  selector: 'driver-route-assigned-location-card',
  templateUrl: './route-assigned-location-card.component.html',
  styleUrls: ['./route-assigned-location-card.component.scss']
})
export class RouteAssignedLocationCardComponent implements OnInit {

  @Input() location: RouteLocation = null;

  constructor() { }

  ngOnInit(): void {
    console.log(this.location);
  }

}
