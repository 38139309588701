// XHA: Documentació Loader:
//https://www.freakyjolly.com/angular-8-7-show-global-progress-bar-loader-on-http-calls-in-3-steps-using-angular-interceptors-in-angular-4-3/

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingComponent } from './_components/loading/loading.component';
import { LoaderService } from './_services/loader.service';
import { LoaderInterceptor } from './_interceptors/loader.interceptor';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    LoadingComponent
  ],
  imports: [
    MatProgressSpinnerModule
  ],
  exports: [
    LoadingComponent
  ],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],  
})  
export class LoaderModule {}