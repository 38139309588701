import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { CustomerBillingInfo, CustomerBillingInfoAdapter } from './customer-billing-info';

export class Customer {

    constructor(
        public name: string = '',
        public phone: string = '',
        public email: string = '',
        public billingInfo: CustomerBillingInfo = new CustomerBillingInfo(),
        public id?: string
    ) {}
}

@Injectable({ providedIn: "root" })
export class CustomerAdapter implements AdapterInterface<Customer> {

    constructor(
        private customerBillingInfoAdapter: CustomerBillingInfoAdapter
    ) {}

    adapt(item: any): Customer {

        if(!item) return null;

        return new Customer(
            item['name'], 
            item['phone'], 
            item['email'],
            this.customerBillingInfoAdapter.adapt(item['billingInfo']), 
            item['id']
        );
    }

}
