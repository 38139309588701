import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';

export class Material {

    constructor(
        public name: string = '',
        public code: string = '',
        public id?: string
    ) {}
    
}

@Injectable({ providedIn: "root" })
export class MaterialAdapter implements AdapterInterface<Material> {

    adapt(item: any): Material {

        if(!item) return null;

        return new Material(
            item['name'], 
            item['code'], 
            item['id']
        );
    }

}