import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'x-modules';

import { Carrier } from '@marsol/models/carrier';
import { CarrierAdapter } from '../models/carrier';
import { AddressService } from './address.service';
import { CarrierInfoService } from './carrier-info.service';


@Injectable({
    providedIn: 'root'
})
export class CarrierService {

    static serviceName = 'carriers';
    get serviceName() { return CarrierService.serviceName; }


    constructor(
        private api: ApiService,
        private adapter: CarrierAdapter
    ) { }

    new(data: Partial<Carrier>): Observable<Carrier> {
        console.log(data);
        return this.api.post(this.serviceName, '', data).pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }


    getList(filter = {}): Observable<Carrier[]> {
        return this.api.get(this.serviceName, '', '', filter).pipe(
            map((resp: any) => resp['hydra:member'].map((item: any) => this.adapter.adapt(item)))
        );
    }

    get(id: string): Observable<Carrier> {
        return this.api.get(this.serviceName, '', id).pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }



    update(carrier: Carrier, data: Partial<Carrier>): Observable<Carrier> {

        // Afegim els links als recursos
        console.log(carrier);

        if (data.carrierInfo !== undefined) {
            data.carrierInfo['id'] = this.api.urlToResource(CarrierInfoService.serviceName, carrier.carrierInfo.id);

            if (data.carrierInfo.address?.id) {
                data.carrierInfo.address['id'] = this.api.urlToResource(AddressService.serviceName, carrier.carrierInfo.address.id);
            }
        }

        return this.api.patch(this.serviceName, carrier.id, '', data).pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }



}
