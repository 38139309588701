import { Component, Inject } from '@angular/core';

@Component({
  selector: 'driver-panel-layout',
  templateUrl: './panel-layout.component.html',
  styleUrls: ['./panel-layout.component.scss']
})

export class PanelLayoutComponent {

  constructor(
    @Inject('environment') private environment: any
  ) {}

  get version() { return this.environment.version; }
}
