import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Carrier, CarrierAdapter } from './carrier';
//import { Route, RouteAdapter } from './route';
import { TRUCK_STATUS, TruckStatus, TRUCK_STATUS_DESC } from './_types/truck-status.type';

export class Truck {

    constructor(
        public plate: string = '',
        public status: TruckStatus = TRUCK_STATUS.AVAILABLE,
        public carrier: Carrier = null,
        //public activeRoute: Route = null,
        public id?: string
    ) {}

    get statusDesc() { return TRUCK_STATUS_DESC[this.status]; }
    get isActive() { return this.status === TRUCK_STATUS.ACTIVE; }

};

@Injectable({ providedIn: "root" })
export class TruckAdapter implements AdapterInterface<Truck> {

    constructor(
        private carrierAdapter: CarrierAdapter,
        //private routeAdapter: RouteAdapter
    ) {}

    adapt(item: any): Truck {

        if(!item) return null;

        return new Truck(
            item['plate'], 
            item['status'], 
            this.carrierAdapter.adapt(item['carrier']),
            //this.routeAdapter.adapt(item['activeRoute']? item['activeRoute'] : null),
            item['id']
        );
    }

}