import { Injectable } from '@angular/core';

import { AlertService } from 'x-modules';
import { LoadOrderService } from '@marsol/api/load-order.service';
import { RouteAssignedState } from '../route-assigned.state';
import { LoadOrder } from '@marsol/models/load-order';

@Injectable({ providedIn: 'root' })
export class RouteAssignedUploadFileAction {
    
    constructor(
        private state: RouteAssignedState,
        private alert: AlertService,
        private apiLoadOrder: LoadOrderService,
    ) { }

    run(file: File): void {

        this.state.updating = true;

        this.apiLoadOrder.uploadLoadOrderDocument(this.state.route.loadOrder.id, file).subscribe(
            (loadOrder: LoadOrder) => { 
                console.log(loadOrder);
                this.state.activeCamera = false;
            },
            error => this.alert.error("Ha ocurrido un error"),
            () => this.state.updating = false
        );

    }
}