import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'x-modules';
import { LoadOrder } from '@marsol/models/load-order';
import { environment } from '@environments/environment';
import { Material } from '@marsol/models/material';
import { LoadOrderAdapter } from '../models/load-order';


interface LoadOrderSearchResults {
    list: LoadOrder[],
    totalItems: number
}

@Injectable({
    providedIn: 'root'
})
export class LoadOrderService {

    apiService = 'load_orders';

    constructor(
        private api: ApiService,
        private adapter: LoadOrderAdapter
    ) { }

    new(data: Partial<LoadOrder>): Observable<LoadOrder> {
        return this.api.post(this.apiService, '', data);
    }

    newByTariff(
        tariff: string, // La url al recurs 
        returnTrip: boolean,
        date: string,
        trailerPlate: string,
        material: Material,
        notes: string
    ): Observable<LoadOrder> {

        const json = {
            tariff: tariff,
            returnTrip: returnTrip,
            date: date,
            weight: 0,
            trailerPlate: trailerPlate,
            material:  this.api.urlToResource('materials', material.id),
            notes: notes
        }

        if (!environment.production) {
            console.log(json);
        }
        return this.api.post(this.apiService, 'by-tariff', json).pipe(
            map(loadOrderCreated => this.adapter.adapt(loadOrderCreated))
        );

    }


    getList(filter = null): Observable<LoadOrderSearchResults> {

        return this.api.get(this.apiService, '', '', filter).pipe(
            // Rebem un array i mapegem els seus elements amb el adapter per obtenir una llista de LoadOrders
            map((resp: any) => { 
                return { 
                    list: resp['hydra:member'].map((item: any) => this.adapter.adapt(item)), 
                    totalItems: resp['hydra:totalItems']
                }
            }) 
        );
    }

    get(id: string): Observable<LoadOrder> {
        return this.api.get(this.apiService, '', id).pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }

    update(id: string, data: Partial<LoadOrder>): Observable<LoadOrder> {
        return this.api.patch(this.apiService, id, '', data).pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }

    uploadLoadOrderDocument(id: string, file: File): Observable<LoadOrder> {

        let formData: FormData = new FormData()
        formData.append('file', file, file.name);
        
        return this.api.postFile(this.apiService, id, 'load-order-document', formData).pipe(
            map((item: any) => this.adapter.adapt(item) )
        );
    }
    

    sendToBill(id: string): Observable<LoadOrder> {
        return this.api.put(this.apiService, id, 'send-to-bill').pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }

	setInvoiced(id: string): Observable<LoadOrder> {
        return this.api.put(this.apiService, id, 'set-invoiced').pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }

    setUnloaded(id: string): Observable<LoadOrder> {
        return this.api.put(this.apiService, id, 'set-unloaded').pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }

    cancel(id: string): Observable<LoadOrder> {
        return this.api.put(this.apiService, id, 'cancel').pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }

}
