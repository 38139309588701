import { AssetsSourcePipe } from './assets-source-pipe';
import { NgModule } from "@angular/core";


@NgModule({
  declarations: [
    AssetsSourcePipe
  ],
  exports: [
    AssetsSourcePipe
  ]
})   
export class PipesModule { }