/**
 * Ens ajuda a l'assignació dels status permesos:
 * let status: LoadOrderStatus = LOAD_ORDER_STATUS.NEW
 */
export const enum LOAD_ORDER_STATUS {
    GENERATED = 'GENERATED',
    ASSIGNED = 'ASSIGNED',
    PARKED = 'PARKED',
    UNLOADED = 'UNLOADED',
    TOBILL = 'TOBILL',    
	INVOICED = 'INVOICED',
    CANCELLED = 'CANCELLED'
}

/**
 * Defineux els valors permesos
 */
export const LOAD_ORDER_STATUS_SUPPORTED_VALUES = [ 
    'GENERATED', 'ASSIGNED', 'PARKED', 'UNLOADED', 'TOBILL', 'INVOICED', 'CANCELLED' 
] as const;

/**
 * Defineix el tipus en base als valors permesos
 */
export type LoadOrderStatus = typeof LOAD_ORDER_STATUS_SUPPORTED_VALUES[number];


/**
 * Defineix la descripció del valor del tipus definit
 *    <option *ngFor="let id of LOAD_ORDER_STATUS_VALUES" [ngValue]="id">
 *       {{ LOAD_ORDER_STATUS_DESC[id] }}
 *    </option>
 */
export const LOAD_ORDER_STATUS_DESC: Record<LoadOrderStatus, string> = {
    GENERATED: 'GENERADA',
    ASSIGNED: 'ASIGNADA',
    PARKED: 'APARCADA',
    UNLOADED: 'DESCARGADA',
    TOBILL: 'PEND. FACT.',
	INVOICED: 'FACTURADA',
    CANCELLED: 'CANCELADA'
} as const;
