<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation" color="accent">
        <!--this is a place for us to add side-nav code-->
        <driver-panel-sidenav-menu (sidenavClose)="sidenav.close()"></driver-panel-sidenav-menu>
        <div>
            <br>
            <small>&nbsp;&nbsp;ver. {{ version }}</small>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <!--in here all the content must reside.-->
        <div class="wrapper">
            <driver-panel-header (sidenavToggle)="sidenav.toggle()"></driver-panel-header>

            <main>
                <router-outlet></router-outlet>
            </main>
            <div class="push"></div>
        </div>
        <driver-panel-footer></driver-panel-footer>

    </mat-sidenav-content>
</mat-sidenav-container>