import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { RouteAssignedState } from './route-assigned.state';
import { RouteAssignedLoadAction } from './_actions/route-assigned-load.action';
import { RouteAssignedUnloadAction } from './_actions/route-assigned-unload.action';

@Component({
    selector: 'app-route-assigned',
    templateUrl: './route-assigned.component.html',
    styleUrls: ['./route-assigned.component.scss']
})
export class RouteAssignedComponent implements OnInit {

    updating$: Observable<boolean>;

    constructor(
        private state: RouteAssignedState,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        public dialog: MatDialog,
        private loadAction: RouteAssignedLoadAction,
        private unloadAction: RouteAssignedUnloadAction

    ) {
        this.matIconRegistry.addSvgIcon(
            "gmaps",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gmaps_icon.svg")
        );

    }

    get route$() { return this.state.route$; }
    get activeCamera$() { return this.state.activeCamera$; }

    ngOnInit(): void {

        // Observable per actualitzar la route quan acabem de fer un update.
        // ** Es subscriu a través del | async de la plantilla
        this.updating$ = this.state.updating$.pipe(
            filter(updating => updating === false), // Filtra només quan el valor és null
            filter(updating => this.state.route !== null), // Filtra si route té algun valor inicial
            tap(updating => {
                console.log("updating");
                this.loadAction.route()
                //setTimeout(() => this.loadAction.loadOrder(this.state.loadOrder.id), 300); // Deixem temps pq es guardi la info a la BD...
            })
        );

        this.loadAction.route();
    }

    onRefresh() {
        this.loadAction.route();
    }
}
