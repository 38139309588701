import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoaderModule } from '@marsol/ui/loader/loader.module';
import { AlertModule } from '@marsol/ui/alert/alert.module';

import { LayoutModule } from './layout/layout.module';
import { SecurityModule } from  './security/security.module';
import { PanelModule } from './panel/panel.module';

import { environment } from '../environments/environment';
import { JwtInterceptor } from 'x-modules';
import { UnauthResponseInterceptor } from 'x-modules';
import { TimestampRequestInterceptor } from 'x-modules';

import localeES from "@angular/common/locales/es";
import { registerLocaleData } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ToastrModule } from 'ngx-toastr';

registerLocaleData(localeES, "es");

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    AppRouting,

    LoaderModule,
    AlertModule,

    LayoutModule,
    SecurityModule,
    PanelModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    ToastrModule.forRoot(), // ToastrModule added

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
  ],
  providers: [
    { provide:  'environment', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthResponseInterceptor, multi: true },
//    { provide: HTTP_INTERCEPTORS, useClass: ContentTypeRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimestampRequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "es-ES" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
