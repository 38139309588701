import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'assetsSource'
})
export class AssetsSourcePipe implements PipeTransform {

  // This will be the url to our assets server.
  // Ex: https://our-assets.com/assets
  private assetsURL: string;

  constructor() {
    this.assetsURL = environment.urls.assets;
   }

  transform(imgName: string): string {
    return `${this.assetsURL}/${imgName}`;
  }

}