import { NgModule } from '@angular/core';

import { TetrisComponent } from './tetris.component';
import { BoardComponent } from './board.component';
import { AngularMaterialModule } from '@driver/angular-material/angular-material.module';
import { SharedModule } from '@driver/shared/shared.module';

@NgModule({
  declarations: [
    TetrisComponent, 
    BoardComponent
  ],
  imports: [
    SharedModule,
    AngularMaterialModule
  ],
  providers: [],
})
export class TetrisModule {}
