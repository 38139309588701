import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';

export class Address { //implements AddressInterface {

    constructor(
        public address: string = '',
        public zipCode: string = '',
        public city: string = '',
        public state: string = '',
        public country: string = '',
        public id?: string
    ) {}

}

@Injectable({ providedIn: "root" })
export class AddressAdapter implements AdapterInterface<Address> {

    adapt(item: any): Address {

        if(!item) return null;
        
        return new Address(
            item['address'], 
            item['zipCode'], 
            item['city'],
            item['state'], 
            item['country'], 
            item['id']
        );
    }

}