<div class="container">
    <div *ngIf="(updating$ | async)">Updating...</div>
    <div *ngIf="(route$ | async) === null">
        <driver-route-assigned-no-route (OnRefresh)="onRefresh()"></driver-route-assigned-no-route>
    </div>

    <ng-container *ngIf="(route$ | async) as route">

        <driver-route-assigned-details *ngIf="(activeCamera$ | async) === false">
        </driver-route-assigned-details>

        <driver-route-assigned-camera *ngIf="(activeCamera$ | async) === true">
        </driver-route-assigned-camera>
    </ng-container>
</div>