import { TariffLocation } from '@marsol/models/tariff-location';

export const BrandLocation: TariffLocation = {
    name: 'Demo',
    gps: '0.000000,0.000000',
    phone1: '',
    phone2: '',
    email: 'info@brand.online',
    address: {
        address: 'c/ de la Demo s/n',
        zipCode: '08050',
        city: 'Demo City',
        state: 'Barcelona',
        country: 'Spain',
    }
}

export const environment = {
  production: false,

  //apiUrl: 'https://api.marsol.workitout.cat/api/v1',

  apiHost: 'https://api-demo.workitout.cat',
  apiRoute: '/api/v1',
  firebaseRoute: 'https://api-demo.workitout.cat/firebase-demo',

  minPassLength: 6,
  
  locale_id: 'es-ES',
  
  firebaseConfig: {
    apiKey: "AIzaSyBz02Qe63QTwHibFNV57QSiNj0yeXn7Jkc",
    authDomain: "workitout-c1ecd.firebaseapp.com",
    projectId: "workitout-c1ecd",
    storageBucket: "workitout-c1ecd.appspot.com",
    messagingSenderId: "757359126981",
    appId: "1:757359126981:web:cf729c74a370a3cdf843ac",
    measurementId: "G-G5EYHDBV1J"
  },
  
  urls: {
    assets: "/assets/images/demo"
  }
};
