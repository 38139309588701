import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';

export class User {

    password?: string = null; // només s'utilitza quan es crea un usuari
    roles?: Array<string> = [];
        
    constructor(
        public username: string = "",
        public id?: string
    ) {}

}

@Injectable({ providedIn: "root" })
export class UserAdapter implements AdapterInterface<User> {

    adapt(item: any): User {

        if(!item) return null;

        var user = new User(
            item['username'], 
            item['id']
        );
        if (item['roles']) {
            user.roles = item['roles'];
        }
        return user;
    }

}