export const enum TRUCK_STATUS {
    AVAILABLE = 'AVAILABLE',
    ACTIVE = 'ACTIVE',
    UNAVAILABLE = 'UNAVAILABLE',
}

export const TRUCK_STATUS_SUPPORTED_VALUES = [ 
    'AVAILABLE', 'ACTIVE', 'UNAVAILABLE' 
] as const;

export type TruckStatus = typeof TRUCK_STATUS_SUPPORTED_VALUES[number];

export const TRUCK_STATUS_DESC: Record<TruckStatus, string> = {
    AVAILABLE: 'DISPONIBLE',
    ACTIVE: 'ACTIVE',
    UNAVAILABLE: 'NO DISPONIBLE'
} as const;

