import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AlertModule } from '@marsol/ui/alert/alert.module';

import { LayoutComponent } from './layout.component';

@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    FlexLayoutModule,
    AlertModule
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule { }
