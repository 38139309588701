import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AlertService } from 'x-modules';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'x-modules';
import { mergeMapTo } from 'rxjs/operators';
//import { ApiResponse } from '@app/core/_modules/api/_models/api-response';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted = false;
  public returnUrl: string;

  constructor(
    @Inject('environment') private environment: any,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private msg: AngularFireMessaging,
    private http: HttpClient
  ) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }


  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }



  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    var user = this.loginForm.value;
    console.log(user);


    this.auth.login(user.username, user.password).subscribe(
      (resp: any) => {
        console.log(resp);
        if (!this.auth.isLoggedIn) {
          this.alert.error('Ha ocurrido un error con la autenticación, por favor intentelo más tarde.');
          return;
        }
        
        this.msg.requestPermission
            .pipe(mergeMapTo(this.msg.tokenChanges))
            .subscribe(
                (token) => { 
                  console.log('Permission granted! Save to the server!', token); 
                  this.http.post(
                    `${this.environment.firebaseRoute}/messaging/refreshToken`,
                    {
                      username: user.username,
                      token: token,
                      role: 'ROLE_DRIVER'
                    }
                  ).subscribe(() => {
                    this.router.navigate([this.auth.getHomeUrl()]);
                  })
                },
                (error) => { 
                  console.log('Permission denied!', error);
                  this.router.navigate([this.auth.getHomeUrl()]);
                },  
            );
        
      },
      (error: any) => {
        console.log(error);
        if(error.status === 401) {
          this.alert.error('Nombre de usuario o contraseña incorrectos');
        }
        else {
          this.alert.error(error.message);
        }
      }
      );

  }
}
