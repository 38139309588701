import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Address, AddressAdapter } from './address';


export class LoadOrderBillingInfo {

    constructor(
        public vatId: string = '',
        public name: string = '',
        public address: Address = new Address(),
        public weight: number = 0,
        public amount: number = 0,
        public customerOffice: string = '',
        public id?: string
    
    ) {}

}

@Injectable({ providedIn: "root" })
export class LoadOrderBillingInfoAdapter implements AdapterInterface<LoadOrderBillingInfo> {

    constructor(
        private addressAdapter: AddressAdapter
    ) {}

    adapt(item: any): LoadOrderBillingInfo {

        if(!item) return null;

        return new LoadOrderBillingInfo(
            item['vatId'],
            item['name'],
            this.addressAdapter.adapt(item['address']), 
            item['weight'], 
            item['amount'], 
            item['customerOffice'],
            item['id']
        );
    }

}