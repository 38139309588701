import { environment } from '../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  entornTest = false;

  constructor(private msg: AngularFireMessaging, private toastr: ToastrService) { 
    this.entornTest = !environment.production;
  }

  ngOnInit(): void {

      this.msg.messages.subscribe((message: any) => { 
          let notification = message.notification;
          this.toastr.success(notification.title, notification.body, {
              disableTimeOut: true,
              positionClass: 'toast-top-center'
          });
      });

      this.msg.onMessage((payload) => {
          let notification = payload.notification;
          this.toastr.success(notification.title, notification.body, {
              disableTimeOut: true,
              positionClass: 'toast-top-center'
          });
      });   
  }
}
