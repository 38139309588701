import { NgModule } from '@angular/core';
import { PanelComponent } from './panel.component';

import { PanelRouting } from './panel.routing';
import { PanelLayoutModule } from './layout/panel-layout.module';
import { RouteModule } from './route/route.module';
import { TetrisModule } from './tetris/tetris.module';


@NgModule({
  declarations: [PanelComponent],
  imports: [
    PanelRouting,

    PanelLayoutModule,
    RouteModule,
    TetrisModule
  ]
})
export class PanelModule { }
