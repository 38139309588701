<driver-layout id="driver-layout-content">
  <div class="container" fxLayout="column" fxLayoutAlign="center center">
    <img width="120" [src]="'logo_trans.png' | assetsSource">
    <mat-card fxLayout="column" fxLayoutAlign="center center">
      <mat-card-title>Login</mat-card-title>
      <mat-card-content>
        <form novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <p>
            <mat-form-field>
              <mat-label>Usuario</mat-label>
              <input type="text" matInput formControlName="username" autocomplete="off">
              <mat-error *ngIf="f.username.invalid && f.username.touched">Este campo es obligatorio</mat-error>

            </mat-form-field>
          </p>

          <p>
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input type="password" matInput formControlName="password">
              <mat-error *ngIf="f.password.invalid && f.password.touched">Este campo es obligatorio</mat-error>
            </mat-form-field>
          </p>

          <div class="actions" fxLayout="column" fxLayoutAlign="center center">
            <p>
              <button type=submit mat-raised-button color=accent>Entrar</button>
            </p>

            <!-- <p>
              <a mat-button color=accent routerLink="/reset-password-request">Reset password</a>
            </p> -->
          </div>

        </form>
      </mat-card-content>
    </mat-card>
  </div>
</driver-layout>