<mat-toolbar color="accent">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-icon-button onclick="this.blur();" (click)="onToggleSidenav();">
      <mat-icon>menu</mat-icon>
    </button>
    {{ (driver$ | async)?.name }}
    <button mat-icon-button (click)="logout()">
      <!-- <mat-icon class="icon-svg" svgIcon="logout"></mat-icon> -->
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</mat-toolbar>
