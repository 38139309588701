import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'driver-route-assigned-camera-snapshot',
  templateUrl: './route-assigned-camera-snapshot.component.html',
  styleUrls: ['./route-assigned-camera-snapshot.component.scss']
})
export class RouteAssignedCameraSnapshotComponent implements OnInit {

  @Input() snapshot: any
  @Output() send: EventEmitter<void> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onBack() {
    this.back.emit();
  }

  onSend() {
    this.send.emit();
  }

}
