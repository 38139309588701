<div class="grid">
    <button mat-raised-button (click)="play()" class="play-button">Start</button>
    <br>
    <div class="game-area">
        <div class="game-board-container">
            <canvas #board class="game-board"></canvas>
        </div>
        <div>
            <h1>TETRIS</h1>
            <div class="next-container">
                <p>Next:</p>
                <canvas #next class="next"></canvas>
                <p>Score: {{ points }}</p>
                <p>Lines: {{ lines }}</p>
                <p>Level: {{ level }}</p>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <div>
            <button mat-stroked-button  color="primary" (click)="moveUp()"><mat-icon>arrow_drop_up </mat-icon></button>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
            <button mat-stroked-button color="primary" (click)="moveLeft()"><mat-icon>arrow_left</mat-icon></button>
            <div class="key_spacer"></div>
            <button mat-stroked-button  color="primary" (click)="moveRight()"><mat-icon>arrow_right</mat-icon></button>
        </div>
        <div>
            <button mat-stroked-button color="primary" (click)="moveDown()"><mat-icon>arrow_drop_down</mat-icon></button>
        </div>
    </div>
</div>