import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'x-modules';

import { PanelComponent } from './panel.component';
import { TetrisComponent } from './tetris/tetris.component';


const routes: Routes = [

  // URLs privades
  {
    path: '', component: PanelComponent, canActivate: [AuthGuard],
    children: [

      { path: 'tetris', component: TetrisComponent },
      { path: 'route', loadChildren: './route/route.module#RouteModule' },
      { path: '**', redirectTo: '/route/assigned', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PanelRouting { }