import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { tariffCalcAmount } from './tariff';
import { PRICE_TYPE_DESC } from './_types/price-type.type';

export class RoutePriceInfo {

    constructor(
        public priceType: string = '',
        public tonPrice: number = 0,
        public journeyPrice: number = 0,
        public id?: string
    ) {}

    get priceTypeDesc() {
        return PRICE_TYPE_DESC[this.priceType];
    }

    calcAmount(weightToBill: number ): number {
        return tariffCalcAmount( {
            priceType: this.priceType,
            weightToBill: weightToBill,
            tonPrice: this.tonPrice,
            journeyPrice: this.journeyPrice
        });
    }
}

@Injectable({ providedIn: "root" })
export class RoutePriceInfoAdapter implements AdapterInterface<RoutePriceInfo> {

    adapt(item: any): RoutePriceInfo {

        if(!item) return null;

        return new RoutePriceInfo(
            item['priceType'], 
            item['tonPrice'], 
            item['journeyPrice'],
            item['id']
        );
    }

}