<mat-card class="location-card-container mat-elevation-z0" *ngIf="location !== null">
    <div fxLayout="row">
        <div class="address" fxFlex="60">
            <p>{{ location.address.address }}</p>
            <p>{{ location.address.zipCode }} {{ location.address.city }} ({{ location.address.state }})</p>
            <p>{{ location.address.country}}</p>
        </div>
        <div class="gps" fxFlex="40" fxLayout="column" fxLayoutAlign="start end">
            <small *ngIf="location.gps">{{ location.gps }}</small>
            <small *ngIf="!location.gps"> No GPS</small>
            <a *ngIf="location.gps"
                mat-button [href]="'https://maps.google.com/?q='+location.gps" target="_blank">

                <mat-icon svgIcon="gmaps"></mat-icon>
            </a>
        </div>
    </div>

    <div class="phone" fxLayout="row" *ngIf="location.phone1 || location.phone2">
        <ng-container *ngIf="location.phone1">
            <mat-icon inline>phone</mat-icon>&nbsp;<span>{{ location.phone1 }}</span>
            <span fxFlex></span>
        </ng-container>
        <ng-container *ngIf="location.phone2">
            <mat-icon inline>phone</mat-icon>&nbsp;<span>{{ location.phone2 }}</span>
        </ng-container>
     </div>

</mat-card>
