import { Injectable } from '@angular/core';

import { RouteService } from '@marsol/api/route.service';
import { ROUTE_STATUS } from '@marsol/models/_types/route-status.type';
import { RouteAssignedState } from '../route-assigned.state';

@Injectable({ providedIn: 'root' })
export class RouteAssignedLoadAction {
    
    constructor(
        private state: RouteAssignedState,
        private apiRoute: RouteService,
    ) { }

    route(): void {
        this.apiRoute.getList({ status: ROUTE_STATUS.ASSIGNED }).subscribe(
            results => { 
                console.log(results);
                this.state.route = results.totalItems > 0 ? results.list[0] : null 
        });

    }
}