import { NgModule } from '@angular/core';

import { RouteRouting } from './route.routing';

import { RouteAssignedModule } from './assigned/route-assigned.module';

@NgModule({
  declarations: [],
  imports: [
    RouteRouting,
    RouteAssignedModule,
  ]
})
export class RouteModule { }
