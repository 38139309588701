import { Injectable } from '@angular/core';

import { AdapterInterface } from 'x-modules/lib/core/adapter';

import { Carrier, CarrierAdapter } from './carrier';
//import { Route, RouteAdapter } from './route';
import { Truck, TruckAdapter } from './truck';
import { User, UserAdapter } from './user';
import { DRIVER_STATUS, DRIVER_STATUS_DESC } from './_types/driver-status.type';


export class Driver {

    constructor(
        public name: string = '',
        public status: DRIVER_STATUS = DRIVER_STATUS.ACTIVE,
        public carrier: Carrier = null,
        public user: User = null,
        public truck: Truck = null,
        //public activeRoute: Route = null,
        public id?: string
    ) {}

    get statusDesc() { return DRIVER_STATUS_DESC[this.status]; }
    get isActive() { return this.status === DRIVER_STATUS.ACTIVE; }

}


@Injectable({ providedIn: "root" })
export class DriverAdapter implements AdapterInterface<Driver> {

    constructor(
        private carrierAdapter: CarrierAdapter,
        private userAdapter: UserAdapter,
        private truckAdapter: TruckAdapter,
        //private routeAdapter: RouteAdapter
    ) {}

    adapt(item: any): Driver {

        if(!item) return null;

        return new Driver(
            item['name'], 
            item['status'], 
            null, //this.carrierAdapter.adapt(item['carrier']? item['carrier'] : null),
            this.userAdapter.adapt(item['user']? item['user'] : null),
            this.truckAdapter.adapt(item['truck']? item['truck'] : null),
            //this.routeAdapter.adapt(item['activeRoute']? item['activeRoute'] : null),
            item['id']
        );
    }

}
