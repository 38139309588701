import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Driver } from '@marsol/models/driver';


@Injectable({
    providedIn: 'root'
  })
  export class PanelState {
  
    private _driver = new BehaviorSubject<Driver>(null);
    private _updating = new BehaviorSubject<boolean>(false);
  
    constructor( ) {}

    get driver$() { return this._driver.asObservable(); }
    get driver() { return this._driver.getValue(); } 
    set driver(value: Driver) { this._driver.next( value ); }
  
    get updating$(): Observable<boolean> { return this._updating.asObservable(); }
    set updating(value: boolean) { this._updating.next( value ); }
  
  
  }
  