import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from 'x-modules';

import { Route } from '@marsol/models/route';
import { map } from 'rxjs/operators';
import { RouteLocation } from '@marsol/models/route-location';
import { LOAD_ORDER_STATUS } from '../models/_types/load-order-status.type';
import { RouteAdapter } from '../models/route';

interface RouteSearchResults {
    list: Route[],
    totalItems: number
}


@Injectable({
	providedIn: 'root'
})
export class RouteService {

	apiService = 'routes';

	constructor(
		private api: ApiService,
		private adapter: RouteAdapter
	) { }

	getList(filter = null): Observable<RouteSearchResults> {

		/* TODO: Parametres de filtre i paginació */
		return this.api.get(this.apiService, '', '', filter).pipe(
			map((resp: any) => {
				return {
					list: resp['hydra:member'].map((item: any) => this.adapter.adapt(item)),
					totalItems: resp['hydra:totalItems']
				}
			})
		);
	}

	addToLoadOrderInternal( data: {
			loadOrderId: string, 
			date: string,
            carrierId: string, 
            truckId: string, 
            driverId: string, 
		}
	): Observable<Route> {

		return this.api.post(this.apiService, 'add-to-load-order', {
			loadOrder: this.api.urlToResource('load_orders', data.loadOrderId),
			date: data.date,
			carrier: this.api.urlToResource('carriers', data.carrierId),
			truck: this.api.urlToResource('trucks', data.truckId),
			driver: this.api.urlToResource('drivers', data.driverId)
		});
	}

	addToLoadOrderExternal( data: {
			loadOrderId: string, 
			date: string,
			carrierId: string, 
			truckPlate: string, 
			expectedLoadDate: string,
			expectedUnloadDate: string
		}
	): Observable<Route> {

		return this.api.post(this.apiService, 'add-to-load-order', {
			loadOrder: this.api.urlToResource('load_orders', data.loadOrderId),
			date: data.date,
			carrier: this.api.urlToResource('carriers', data.carrierId),
			truckPlate: data.truckPlate,
			expectedLoadDate: data.expectedLoadDate,
			expectedUnloadDate: data.expectedUnloadDate
		});
	}

	get(id: string): Observable<Route> {
		return this.api.get(this.apiService, '', id).pipe(
			map(resp => this.adapter.adapt(resp))
		);
	}

	update(id: string, data: Partial<Route>): Observable<Route> {
		return this.api.patch(this.apiService, id, '', data).pipe(
			map(resp => this.adapter.adapt(resp))
		);
	}

	cancel(id: string): Observable<Route> {
		return this.api.put(this.apiService, id, 'cancel').pipe(
			map(resp => this.adapter.adapt(resp))
		);
	}

	undoRoute(id: string): Observable<Route> {
		return this.api.put(this.apiService, id, 'undo').pipe(
			map(resp => this.adapter.adapt(resp))
		);
	}

	finalize(id: string,
		data: {
			loadOrderStatus: LOAD_ORDER_STATUS.UNLOADED | LOAD_ORDER_STATUS.PARKED,
			toLocation: RouteLocation
		}
	) {
		return this.api.put(this.apiService, id, 'finalize', data).pipe(
			map(resp => this.adapter.adapt(resp))
		);
	}

}
