import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Customer, CustomerAdapter } from './customer';

import { TariffLocation, TariffLocationAdapter } from './tariff-location';
import { TariffPriceInfo, TariffPriceInfoAdapter } from './tariff-price-info';
import { PRICE_TYPE } from './_types/price-type.type';

export class Tariff {

    constructor(
        public description: string = '',
        public distance: number = 0,
        public customerOffice = '',
        public customer: Customer = null,
        public fromLocation: TariffLocation = new TariffLocation(),
        public toLocation: TariffLocation = new TariffLocation(),
        public priceInfo: TariffPriceInfo = new TariffPriceInfo(),
        public id?: string
    ) { }

}

@Injectable({ providedIn: "root" })
export class TariffAdapter implements AdapterInterface<Tariff> {

    constructor(
        private tariffLocationAdapter: TariffLocationAdapter,
        private tariffPriceInfoAdapter: TariffPriceInfoAdapter,
        private customerAdapter: CustomerAdapter
    ) {}

    adapt(item: any): Tariff {

        if(!item) return null;

        return new Tariff(
            item['description'], 
            item['distance'],
            item['customerOffice'],
            this.customerAdapter.adapt(item['customer']),
            this.tariffLocationAdapter.adapt(item['fromLocation']),
            this.tariffLocationAdapter.adapt(item['toLocation']),
            this.tariffPriceInfoAdapter.adapt(item['priceInfo']? item['priceInfo'] : null),
            item['id']
        );
    }
}

export function tariffCalcAmount( values: { 
    priceType: string, 
    weightToBill: number, 
    tonPrice: number,
    journeyPrice: number,
}): number {

    switch(values.priceType) {

        case PRICE_TYPE.FROM_WEIGHT_X_TONE_PRICE:
        case PRICE_TYPE.TO_WEIGHT_X_TONE_PRICE:
            if(isNaN(+values.weightToBill) || isNaN(+values.weightToBill)) return 0;
            return Number(values.weightToBill) * values.tonPrice;

        case PRICE_TYPE.JOURNEY:
        case PRICE_TYPE.TIME:
            if(isNaN(+values.journeyPrice)) return 0;
            return values.journeyPrice;
            
        default: 
            return 0;
    }

}





