import { Component, OnInit } from '@angular/core';

import { RouteAssignedState } from '../../route-assigned.state';
import { RouteAssignedUploadFileAction } from '../../_actions/route-assigned-upload-file.action';

@Component({
    selector: 'driver-route-assigned-camera',
    templateUrl: './route-assigned-camera.component.html',
    styleUrls: ['./route-assigned-camera.component.scss']
})
export class RouteAssignedCameraComponent implements OnInit {

    snapshot: any = null;

    constructor(
        private state: RouteAssignedState,
        private uploadFileAction: RouteAssignedUploadFileAction,
    ) { }

    ngOnInit(): void {
    }

    onCapture(value: any) {
        //console.log(value);
        this.snapshot = value;
    }

    onCancelCapture() {
        this.state.activeCamera = false;
    }

    onBackSnapshot() {
        this.snapshot = null;
    }

    onSendSnapshot() {

        const file = b64toFile(this.snapshot);
        //console.log(file);
        this.uploadFileAction.run(file);
    }

}

function b64toFile(dataURI): File {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { 'type': mimeString });
    blob['lastModifiedDate'] = (new Date()).toISOString();
    blob['name'] = 'file';
        
    // Figure out what extension the file should have
    switch(blob.type) {
        case 'image/jpeg':
            blob['name'] += '.jpg';
            break;
        case 'image/png':
            blob['name'] += '.png';
            break;
    }
    // cast to a File
    return <File>blob;
}
