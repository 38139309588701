import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Address, AddressAdapter } from './address';

export class RouteLocation {

    constructor(
        public name: string = '',
        public address: Address = new Address(),
        public gps: string = '',
        public phone1: string = '',
        public phone2: string = '',
        public email: string = '',
        public expectedDate: string = '',
        public id?: string
    ) {}

}

@Injectable({ providedIn: "root" })
export class RouteLocationAdapter implements AdapterInterface<RouteLocation> {

    constructor(
        private addressAdapter: AddressAdapter
    ) {}

    adapt(item: any): RouteLocation {

        if(!item) return null;

        return new RouteLocation(
            item['name'], 
            this.addressAdapter.adapt(item['address']), 
            item['gps'],
            item['phone1'],
            item['phone2'],
            item['email'],
            item['expectedDate'],
            item['id']
        );
    }

}