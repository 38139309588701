import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ConfirmDialogModule } from '@marsol/ui/confirm-dialog/confirm-dialog.module';

import { SharedModule } from '@driver/shared/shared.module';

import { RouteAssignedComponent } from './route-assigned.component';

import { RouteAssignedLocationCardComponent } from './_components/location-card/route-assigned-location-card.component';
import { RouteAssignedNoRouteComponent } from './_components/no-route/route-assigned-no-route.component';
import { RouteAssignedDetailsComponent } from './_components/details/route-assigned-details.component';
import { RouteAssignedCameraComponent } from './_components/camera/route-assigned-camera.component';
import { RouteAssignedCameraSnapshotComponent } from './_components/camera/snapshot/route-assigned-camera-snapshot.component';
import { RouteAssignedCameraPreviewComponent } from './_components/camera/preview/route-assigned-camera-preview.component';



@NgModule({
    declarations: [
        RouteAssignedComponent,
        RouteAssignedLocationCardComponent,
        RouteAssignedNoRouteComponent,
        RouteAssignedDetailsComponent,
        RouteAssignedCameraComponent,
        RouteAssignedCameraSnapshotComponent,
        RouteAssignedCameraPreviewComponent
    ],
    imports: [
        RouterModule,
        SharedModule,

        ConfirmDialogModule,
    ]
})
export class RouteAssignedModule { }
