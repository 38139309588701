import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'x-modules';

@Component({ 
  selector: 'driver-panel-sidenav-menu',
  templateUrl: 'panel-sidenav-menu.component.html',
  styleUrls: ['panel-sidenav-menu.component.scss']
 })
  
export class PanelSidenavMenuComponent implements OnInit {

  @Output() sidenavClose = new EventEmitter();

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

    ngOnInit() {
    }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  logout() {
    this.authService.logout();
    this.router.navigate([this.authService.getLoginUrl()]);
  }    
    


}