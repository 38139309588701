import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Address, AddressAdapter } from './address';


export class LoadOrderLocation {

    constructor(
        public name: string = '',
        public address: Address = new Address(),
        public gps: string = '',
        public email: string = '',
        public phone1: string = '',
        public phone2: string = '',
        public weight: number = 0,
        public id?: string
    ) {}

}

@Injectable({ providedIn: "root" })
export class LoadOrderLocationAdapter implements AdapterInterface<LoadOrderLocation> {

    constructor(
        private addressAdapter: AddressAdapter
    ) {}

    adapt(item: any): LoadOrderLocation {

        if(!item) return null;

        return new LoadOrderLocation(
            item['name'], 
            this.addressAdapter.adapt(item['address']), 
            item['gps'],
            item['email'],
            item['phone1'],
            item['phone2'],
            item['weight'],
            item['id']
        );
    }

}