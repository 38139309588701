import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Address, AddressAdapter } from './address';


export class CarrierInfo {

    constructor(
        public discount: number = 0,
        public vatId: string = '',
        public name: string = '',
        public address: Address = new Address(),
        public id?: string
    ) {}
}

@Injectable({ providedIn: "root" })
export class CarrierInfoAdapter implements AdapterInterface<CarrierInfo> {

    constructor(
        private addressAdapter: AddressAdapter
    ) {}

    adapt(item: any): CarrierInfo {

        if(!item) return null;

        return new CarrierInfo(
            item['discount'], 
            item['vatId'], 
            item['name'],
            this.addressAdapter.adapt(item['address']), 
            item['id']
        );
    }

}