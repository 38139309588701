export const enum PRICE_TYPE {
    JOURNEY = 'JOURNEY',
    FROM_WEIGHT_X_TONE_PRICE = 'FROM_WEIGHT_X_TONE_PRICE',
    TO_WEIGHT_X_TONE_PRICE = 'TO_WEIGHT_X_TONE_PRICE',
    TIME = 'TIME'
}

/**
 * Defineux els valors permesos
 */
export const PRICE_TYPE_SUPPORTED_VALUES = [ 
    'JOURNEY', 
    'FROM_WEIGHT_X_TONE_PRICE', 
    'TO_WEIGHT_X_TONE_PRICE', 
    'TIME'
] as const;

/**
 * Defineix el tipus en base als valors permesos
 */
type PriceType = typeof PRICE_TYPE_SUPPORTED_VALUES[number];


/**
 * Defineix la descripció del valor del tipus definit
 *    <option *ngFor="let id of LOAD_ORDER_STATUS_VALUES" [ngValue]="id">
 *       {{ LOAD_ORDER_STATUS_DESC[id] }}
 *    </option>
 */
export const PRICE_TYPE_DESC: Record<PriceType, string> = {
    JOURNEY: 'Por viaje',
    FROM_WEIGHT_X_TONE_PRICE: 'Peso origen (Tn) x €',
    TO_WEIGHT_X_TONE_PRICE: 'Peso destino (Tn) x €',
    TIME: 'Por tiempo'
} as const;

export function getPriceTypesSupportedOptions(): Array<{ value: string, label: string }> {

    const optionList: Array<{ value: string, label: string }> = [];

    PRICE_TYPE_SUPPORTED_VALUES.forEach(item => { 
            optionList.push({
                value: item.toString(), 
                label: PRICE_TYPE_DESC[item]
            });
    });

    return optionList;
  
}
