import { Injectable } from "@angular/core";

import { AdapterInterface } from 'x-modules/lib/core/adapter';


export class Document {

    constructor(
        public id?: string
    ) {}
}


@Injectable({ providedIn: "root"})
export class DocumentAdapter implements AdapterInterface<Document> {

    constructor() {}

    adapt(item: any): Document {

        if(!item) return null;

        return new Document(
            item['id']
        );
    }
}