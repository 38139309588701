<mat-toolbar color="accent">
  <span>Menú</span>
  <div fxFlex="auto"></div>
  <button mat-icon-button (click)="onSidenavClose()"><mat-icon>close</mat-icon></button>
</mat-toolbar>
<mat-nav-list>
  <a mat-list-item [routerLink]="['/route/assigned']" (click)="onSidenavClose()">
    <span>Ruta asignada</span>
  </a>

  <!-- Logout -->
  <a mat-list-item (click)="logout()">
    <span>Cerrar sesión</span>
  </a>
</mat-nav-list>
