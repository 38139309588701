import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { tariffCalcAmount } from './tariff';
import { PRICE_TYPE, PRICE_TYPE_DESC } from './_types/price-type.type';

export class LoadOrderPriceInfo {

    constructor(
        public priceType: string = '',
        public tonPrice: number = 0,
        public journeyPrice: number = 0,
        public gasoil?: number,
        public id?: string
    ) {}

    get priceTypeDesc() {
        return PRICE_TYPE_DESC[this.priceType];
    }

    get unitPrice(): number {
        switch(this.priceType) {
            case PRICE_TYPE.FROM_WEIGHT_X_TONE_PRICE:
            case PRICE_TYPE.TO_WEIGHT_X_TONE_PRICE:
                return this.tonPrice;

            case PRICE_TYPE.JOURNEY:
            case PRICE_TYPE.TIME:
                return this.journeyPrice;
            
        }
    }

    calcAmount(weightToBill: number ): number {
        return tariffCalcAmount( {
            priceType: this.priceType,
            weightToBill: weightToBill,
            tonPrice: this.tonPrice,
            journeyPrice: this.journeyPrice
        });
    }
}

@Injectable({ providedIn: "root" })
export class LoadOrderPriceInfoAdapter implements AdapterInterface<LoadOrderPriceInfo> {

    adapt(item: any): LoadOrderPriceInfo {

        if(!item) return null;

        return new LoadOrderPriceInfo(
            item['priceType'], 
            item['tonPrice'], 
            item['journeyPrice'],
            item['gasoil'],
            item['id']
        );
    }

}