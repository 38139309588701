<div class="container" fxLayout="column">

    <div>
        <img src="{{ snapshot }}" />
    </div>

    <div class="buttons" fxLayout="row" fxLayoutAlign="center">
        <div fxFlex fxLayoutAlign="start">
            <button mat-fab color="primary" (click)="onBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div fxFlex fxLayoutAlign="end">
            <button mat-fab color="accent" (click)="onSend()">
                <mat-icon>send</mat-icon>
            </button>
        </div>
    </div>
</div>
