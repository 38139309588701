import { Injectable } from '@angular/core';

import { DriverService } from '@marsol/api/driver.service';
import { PanelState } from '../panel.state';

@Injectable({ providedIn: 'root' })
export class PanelLoadAction {
    
    constructor(
        private state: PanelState,
        private apiDriver: DriverService,
    ) { }

    currentDriver(): void {
        
        this.apiDriver.getCurrent().subscribe(
            driver => {
                console.log(driver);
                this.state.driver = driver
            }
        );
    }
}