import { Inject, Injectable } from '@angular/core';

import { AlertService } from 'x-modules';
import { RouteService } from '@marsol/api/route.service';
import { LOAD_ORDER_STATUS } from '@marsol/models/_types/load-order-status.type';
import { RouteAssignedState } from '../route-assigned.state';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class RouteAssignedUnloadAction {
    
    constructor(
        @Inject('environment') private environment: any,
        private http: HttpClient,
        private state: RouteAssignedState,
        private alert: AlertService,
        private apiRoute: RouteService,
    ) { }

    run(): void {

        this.state.updating = true;

        this.http.post(
            `${this.environment.firebaseRoute}/messaging/loadOrder/${this.state.route.id}`,
            {
              title: 'DESCARGADA',
              message: ''
            }
        ).subscribe(() => {
            // Message sent
        });

        this.apiRoute.finalize(
            this.state.route.id, 
            { loadOrderStatus: LOAD_ORDER_STATUS.UNLOADED,
              toLocation: null })
        .subscribe(
            route => this.state.route = null,
            error => this.alert.error(error.message),
            () => this.state.updating = false
        );

    }
}