/**
 * Ens ajuda a l'assignació dels status permesos:
 * let status: RouteStatus = ROUTE_STATUS.NEW
 */
export const enum ROUTE_STATUS {
    ASSIGNED = 'ASSIGNED',
    FINALIZED = 'FINALIZED',
    CANCELLED = 'CANCELLED'
}

/**
 * Defineux els valors permesos
 */
export const ROUTE_STATUS_SUPPORTED_VALUES = [ 'ASSIGNED', 'FINALIZED', 'CANCELLED' ] as const;

/**
 * Defineix el tipus en base als valors permesos
 */
type RouteStatus = typeof ROUTE_STATUS_SUPPORTED_VALUES[number];


/**
 * Defineix la descripció del valor del tipus definit
 *    <option *ngFor="let id of ROUTE_STATUS_VALUES" [ngValue]="id">
 *       {{ ROUTE_STATUS_DESC[id] }}
 *    </option>
 */
export const ROUTE_STATUS_DESC: Record<RouteStatus, string> = {
    ASSIGNED: 'ASIGNADA',
    FINALIZED: 'FINALIZADA',
    CANCELLED: 'CANCELADA'
} as const;


export function getRouteStatusSupportedOptions(): Array<{ value: string, label: string }> {

    const optionList: Array<{ value: string, label: string }> = [];

    ROUTE_STATUS_SUPPORTED_VALUES.forEach(item => { 
            optionList.push({
                value: item.toString(), 
                label: ROUTE_STATUS_DESC[item]
            });
    });

    return optionList;
  
}
