import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { CarrierInfo, CarrierInfoAdapter } from './carrier-info';
import { CARRIER_TYPE, CarrierType } from './_types/carrier-type.type';

export class Carrier {

    constructor(
        public name: string = '',
        public type: CarrierType = CARRIER_TYPE.COLLABORADOR,
        public status: string = '',
        public email: string = '',
        public phone: string = '',
        public carrierInfo: CarrierInfo = new CarrierInfo(),
        public id?: string
    ) {};

    get isIntern() { return this.type === CARRIER_TYPE.INTERN }
    get isAutonom() { return this.type === CARRIER_TYPE.AUTONOM }
    get isColaborador() { return this.type === CARRIER_TYPE.COLLABORADOR }

};

@Injectable({ providedIn: "root" })
export class CarrierAdapter implements AdapterInterface<Carrier> {

    constructor(
        private carrierInfoAdapter: CarrierInfoAdapter
    ) {}

    adapt(item: any): Carrier {

        if(!item) return null;

        return new Carrier(
            item['name'], 
            item['type'], 
            item['status'], 
            item['email'],
            item['phone'],
            this.carrierInfoAdapter.adapt(item['carrierInfo']),
            item['id']
        );
    }


}

