import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'x-modules';

import { Truck, TruckAdapter } from '@marsol/models/truck';
import { CarrierService } from './carrier.service';
import { TRUCK_STATUS } from '../models/_types/truck-status.type';


@Injectable({
    providedIn: 'root'
})
export class TruckService {

    static serviceName = 'trucks';
    get serviceName() { return TruckService.serviceName; }

    constructor(
        private api: ApiService,
        private adapter: TruckAdapter

    ) { }

    new(data: Partial<Truck>): Observable<Truck> {

        const postData = {
            carrier: this.api.urlToResource(CarrierService.serviceName, data.carrier.id),
            plate: data.plate,
            status: TRUCK_STATUS.AVAILABLE
        }
        console.log(postData);

        return this.api.post(this.serviceName, '', postData).pipe(
            map((resp: any) => this.adapter.adapt(resp))
        );
    }


    getList(filter = null): Observable<Truck[]> {
        return this.api.get(this.serviceName, '', '', filter).pipe(
            map((resp: any) => resp['hydra:member'].map((item: any) => this.adapter.adapt(item)))
        );
    }

    update(truck: Truck, data: Partial<Truck>): Observable<Truck> {

        return this.api.patch(this.serviceName, truck.id, '', data).pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }


    delete(id: string): Observable<boolean> {
        return this.api.delete(this.serviceName, id).pipe(
            map((resp: any) => true));
    }


}
