import { Carrier, CarrierAdapter } from './carrier';
import { RouteLocation, RouteLocationAdapter } from './route-location';
import { RouteCostInfo, RouteCostInfoAdapter } from './route-cost-info';
import { LoadOrder, LoadOrderAdapter } from './load-order';
import { Truck, TruckAdapter } from './truck';
import { Driver, DriverAdapter } from './driver';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { RoutePriceInfo, RoutePriceInfoAdapter } from './route-price-info';
import { tariffCalcAmount } from './tariff';
import { ROUTE_STATUS, ROUTE_STATUS_DESC } from './_types/route-status.type';
import { PRICE_TYPE } from './_types/price-type.type';
import { CARRIER_TYPE } from './_types/carrier-type.type';
import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';

export class Route {

    constructor(
        public number: number = 0,
        public date: string = '',
        public distance: number = 0,
        public status: string = '',
        public notes: string = '',
        public carrierType: string = '',
        public truckPlate: string = '',
        public driverName: string = '',
        public loadOrder: LoadOrder = null,
        public carrier: Carrier = null,
        public truck: Truck = null,
        public driver: Driver = null,
        public costInfo: RouteCostInfo = new RouteCostInfo(),
        public fromLocation: RouteLocation = new RouteLocation(),
        public toLocation: RouteLocation = new RouteLocation(),
        public priceInfo: RoutePriceInfo = new RoutePriceInfo(),
        public id?: string
    ) {}

    get statusDesc() {
        return ROUTE_STATUS_DESC[this.status]
    }

    get amountToBill() {

        const amount = tariffCalcAmount( {
            priceType: this.priceInfo.priceType,
            weightToBill: this.weightToBill,
            tonPrice: this.priceInfo.tonPrice,
            journeyPrice: this.priceInfo.journeyPrice
        });

        return Math.round((amount + Number.EPSILON) * 100 / 100);



        // TODO: revisar pq a vegades el costinfo no té instancia de RouteCostInfo
        // return this.costInfo.calcAmount(
        //     this.loadOrder.fromLocation.weight,
        //     this.loadOrder.toLocation.weight
        // );
    }

    get weightToBill() {

        //if(!(this.costInfo instanceof RouteCostInfo)) return 0;
        
        switch(this.priceInfo.priceType) {

            case PRICE_TYPE.FROM_WEIGHT_X_TONE_PRICE:
                return this.loadOrder.fromLocation.weight;
        
            case PRICE_TYPE.TO_WEIGHT_X_TONE_PRICE:
                return this.loadOrder.toLocation.weight;
        
            default:
                return 0;
        }
    }

    get isInternal() {
        return (this.carrierType === CARRIER_TYPE.INTERN) ||
               (this.carrierType === CARRIER_TYPE.AUTONOM)
    }

    get isExternal() {
        return (this.carrierType === CARRIER_TYPE.COLLABORADOR) 
    }

    get isAssigned() { return this.status === ROUTE_STATUS.ASSIGNED; }
    get isCancelled() { return this.status === ROUTE_STATUS.CANCELLED; }

    getTruckPlate(): string {
        if(this.isInternal) {
            if(this.truck !== undefined) {
                return this.truck.plate;
            }
            else return '';
        }
        
        if(this.isExternal) {
            return this.truckPlate;
        }
    }

}

@Injectable({ providedIn: "root" })
export class RouteAdapter implements AdapterInterface<Route> {

    constructor(
        private loadOrderAdapter: LoadOrderAdapter,
        private carrierAdapter: CarrierAdapter,
        private truckAdapter: TruckAdapter,
        private driverAdapter: DriverAdapter,
        private routeCostInfoAdaapter: RouteCostInfoAdapter,
        private routeLocationAdapter: RouteLocationAdapter,
        private routePriceInfoAdapter: RoutePriceInfoAdapter
    ) {}

    adapt(item: any): Route {

        if(!item) return null;

        return new Route(
            item['number'], 
            item['date'], 
            item['distance'],
            item['status'],
            item['notes'],
            item['carrierType'],
            item['truckPlate'],
            item['driverName'],
            this.loadOrderAdapter.adapt(item['loadOrder']),
            this.carrierAdapter.adapt(item['carrier']),
            this.truckAdapter.adapt(item['truck']? item['truck'] : null),
            this.driverAdapter.adapt(item['driver']? item['driver'] : null),
            this.routeCostInfoAdaapter.adapt(item['costInfo']? item['costInfo'] : null),
            this.routeLocationAdapter.adapt(item['fromLocation']),
            this.routeLocationAdapter.adapt(item['toLocation']),
            this.routePriceInfoAdapter.adapt(item['priceInfo']? item['priceInfo'] : null),
            item['id']
        );
    }

}



