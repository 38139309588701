import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService, Alert } from 'x-modules';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.css']
})

export class AlertComponent implements OnInit, OnDestroy {
  
  private alertsSubscription: Subscription;
  private routerEventsSubscription: Subscription;


  constructor(
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

    
  ngOnInit() {

    this.routerEventsSubscription = this.router.events.subscribe(
      (val) => {
        // Tanquem les alertes quan canviem de ruta.
        this.snackBar.dismiss();
      });

    // Per rebre les notificacions dels serveis de la api o altres missatges
    // del sistema
    this.alertsSubscription = this.alertService.alert$.subscribe(
      (alert: Alert) => {
        console.log(alert);

        this.snackBar.dismiss(); // Per si hi ha algun missatge obert
        this.snackBar.open(alert.text, 'x', 
          { panelClass: ['alert', 'alert-' + alert.type],
            duration: alert.duration });
      });

  }
  

  ngOnDestroy() {
    this.alertsSubscription.unsubscribe();
    this.routerEventsSubscription.unsubscribe();
  }
  
}
