<div class="container" *ngIf="route$ | async as route ">
    <div fxLayout="row" fxLayoutAlign="space-between center" >

        <button mat-fab color="accent" (click)="onRefresh()">
            <mat-icon>refresh</mat-icon>
        </button>

        <span class="title-oc">OC.{{ route.loadOrder.number }} / R.{{ route.number }}</span>

        <button mat-fab color="accent" (click)="onPhoto()">
            <mat-icon>photo_camera</mat-icon>
        </button>

    </div>
    <br>
    <mat-card *ngIf="route.notes">
        <mat-icon inline>notification_important</mat-icon>&nbsp;<span class="value">Nota</span>
        <p>{{ route.notes }}</p>
    </mat-card>

    <mat-card fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="start start">
            <span class="label">Material</span>
            <span class="value">{{ route.loadOrder.material.name }}</span>
        </div>
        <div fxFlex></div>
        <div fxLayout="column" fxLayoutAlign="start end">
            <span class="label">Fecha</span>
            <span class="value">{{ route.date | date:'dd/MM/yyyy' }}</span>
        </div>

    </mat-card>

    <mat-card fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="start start">
            <span class="label">Camión</span>
            <span class="value">{{ route.truck.plate }}</span>
        </div>
        <div fxFlex fxLayoutAlign="center start">
            <div fxLayout="column" fxLayoutAlign="start start">
                <span class="label">Remolque</span>
                <span class="value">{{ route.loadOrder.trailerPlate }}</span>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="start end">
            <span class="label">Distancia</span>
            <span class="value">{{ route.distance }} Kms</span>
        </div>
    </mat-card>

    <mat-card>
        <div class="title-location" fxLayout="row">
            <span class="label">Origen</span>
            <span fxFlex></span>
            <span class="value">{{ route.fromLocation.name }}</span>
        </div>
        <driver-route-assigned-location-card 
            [location]="route.fromLocation">
        </driver-route-assigned-location-card>
    </mat-card>

    <mat-card>
        <div class="title-location" fxLayout="row">
            <span class="label">Destino</span>
            <span fxFlex></span>
            <span class="value">{{ route.toLocation.name }}</span>
        </div>
        <driver-route-assigned-location-card
            [location]="route.toLocation">
        </driver-route-assigned-location-card>
    </mat-card>

    <br>

    <div class="actions">
        <button type=buttion mat-raised-button color="accent" fxFlex
            (click)="onUnload()"
            [disabled]="unloadDisabled">
            Descargar
        </button>&nbsp;
        <button type=buttion mat-raised-button color="primary" fxFlex
            (click)="onPark()"
            [disabled]="parkDisabled">
            Aparcar
        </button>
    </div>

</div>