import { PipesModule } from '../pipes/pipes.module';
import { NgModule } from '@angular/core';

import { AuthContext, AuthContextProvider } from 'x-modules';

import { SharedModule } from '@driver/shared/shared.module';
import { AlertModule } from '@marsol/ui/alert/alert.module';

import { LoginComponent } from './_components/login/login.component';
import { LayoutModule } from '@driver/layout/layout.module';


const AUTH_CONTEXT: AuthContext = {
  currentUserKey: 'currentDriver',
  loginUrl: 'login',
  homeUrl: 'route/assigned',
  role: 'ROLE_DRIVER'
};


@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    SharedModule,
    AlertModule,
    LayoutModule,
    PipesModule
  ],
  providers: [
    { provide: AuthContextProvider, useValue: AUTH_CONTEXT },
  ]
})
export class SecurityModule { }
