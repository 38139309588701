import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PanelState } from '@driver/panel/panel.state';

import { AuthService } from 'x-modules';

@Component({ 
  selector: 'driver-panel-header',
  templateUrl: 'panel-header.component.html',
  styleUrls: ['panel-header.component.scss']
 })
  
export class PanelHeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();

  constructor(
    public state: PanelState,
    private authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

    this.matIconRegistry.addSvgIcon(
      "logout",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/logout.svg")
    );    
  }

  get driver$() { return this.state.driver$ }

  ngOnInit() {
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl(this.authService.getLoginUrl());
  }    
    


}