import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouteAssignedComponent } from './assigned/route-assigned.component';

const routes: Routes = [

  { path: 'assigned', component: RouteAssignedComponent },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RouteRouting { }