export const enum DRIVER_STATUS {
    AVAILABLE = 'AVAILABLE',
    ACTIVE = 'ACTIVE',
    UNAVAILABLE = 'UNAVAILABLE',
}

export const DRIVER_STATUS_SUPPORTED_VALUES = [ 
    'AVAILABLE', 'ACTIVE', 'UNAVAILABLE' 
] as const;
type DriverStatus = typeof DRIVER_STATUS_SUPPORTED_VALUES[number];

export const DRIVER_STATUS_DESC: Record<DriverStatus, string> = {
    AVAILABLE: 'DISPONIBLE',
    ACTIVE: 'ACTIVO',
    UNAVAILABLE: 'NO DISPONIBLE'
} as const;
