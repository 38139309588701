import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from '@marsol/ui/confirm-dialog/confirm-dialog.component';
import { RouteAssignedState } from '../../route-assigned.state';
import { RouteAssignedLoadAction } from '../../_actions/route-assigned-load.action';
import { RouteAssignedUnloadAction } from '../../_actions/route-assigned-unload.action';
import { RouteAssignedParkAction } from '../../_actions/route-assigned-park.action';

@Component({
    selector: 'driver-route-assigned-details',
    templateUrl: './route-assigned-details.component.html',
    styleUrls: ['./route-assigned-details.component.scss']
})
export class RouteAssignedDetailsComponent implements OnInit {

    constructor(
        private state: RouteAssignedState,
        public dialog: MatDialog,
        private loadAction: RouteAssignedLoadAction,
        private unloadAction: RouteAssignedUnloadAction,
        private parkAction: RouteAssignedParkAction
    
    ) { }

    get route$() { return this.state.route$; }
    get unloadDisabled() { return false; /*this.state.route.loadOrder.loadOrderDocument === null; */ }
    get parkDisabled() { return this.state.route.loadOrder.loadOrderDocument !== null; }


    ngOnInit(): void {
    }

    onUnload() {

        this.dialog.open(ConfirmDialogComponent, {
            data: '¿Estas seguro que quieres descargar la orden de carga?'
        })
            .afterClosed().subscribe((confirmation: Boolean) => {
                if (confirmation) {
                    this.unloadAction.run();
                }
            });

    }

    onPark() {

        this.dialog.open(ConfirmDialogComponent, {
            data: '¿Estas seguro que quieres aparcar la orden de carga?'
        })
            .afterClosed().subscribe((confirmation: Boolean) => {
                if (confirmation) {
                    this.parkAction.run();
                }
            });
    }

    onRefresh() {
        this.loadAction.route();
    }

    onPhoto() {
        this.state.activeCamera = true;
    }

}
