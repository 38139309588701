import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'x-modules';
import { CarrierInfo, CarrierInfoAdapter } from '../models/carrier-info';

@Injectable({
  providedIn: 'root'
})
export class CarrierInfoService {

  static serviceName = 'carrier_infos';
  get serviceName() { return CarrierInfoService.serviceName }

  constructor(
    private api: ApiService,
    private adapter: CarrierInfoAdapter,
  ) { }

  get(id: string): Observable<CarrierInfo> {
    return this.api.get(this.serviceName, '', id);
  }

  update(id: string , data: Partial<CarrierInfo>): Observable<CarrierInfo> {
    return this.api.patch(this.serviceName, id, '', data).pipe(
      map(resp => this.adapter.adapt(resp))
    );
  }

}
