import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'x-modules';
import { Address, AddressAdapter } from '@marsol/models/address';


@Injectable({
  providedIn: 'root'
})
export class AddressService {

  static serviceName = 'addresses';
  get serviceName() { return AddressService.serviceName; }

  constructor(
    private api: ApiService,
    private adapter: AddressAdapter
  ) { }

  get(id: string): Observable<Address> {
    return this.api.get(this.serviceName, '', id).pipe(
      map(resp => this.adapter.adapt(resp))
    );
  }

  update(id: string , data: Partial<Address>): Observable<Address> {
    return this.api.patch(this.serviceName, id, '', data).pipe(
      map(resp => this.adapter.adapt(resp))
    );
  }

}
