import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'x-modules';
import { Driver, DriverAdapter } from '@marsol/models/driver';
import { CarrierService } from './carrier.service';
import { DRIVER_STATUS } from '../models/_types/driver-status.type';
import { TruckService } from './truck.service';


@Injectable({ providedIn: 'root' })
export class DriverService {

    static serviceName = 'drivers';
    get serviceName() { return DriverService.serviceName; }
  

    constructor(
        private api: ApiService,
        private adapter: DriverAdapter,
    ) { }

    getList(filter = null): Observable<Driver[]> {

        return this.api.get(this.serviceName, '', '', filter).pipe(
            map((resp: any) => resp['hydra:member'].map((item: any) => this.adapter.adapt(item)) )
        );

    }

    new(data: Partial<Driver>): Observable<Driver> {

        const postData = {
            name: data.name,
            status: DRIVER_STATUS.AVAILABLE,
            user : data.user,
            carrier: this.api.urlToResource(CarrierService.serviceName, data.carrier.id),
            truck: data.truck? this.api.urlToResource(TruckService.serviceName, data.truck.id) : null,
        }



        return this.api.post(this.serviceName, '', postData).pipe(
            map((item: any) => this.adapter.adapt(item) )
        );

    }


    get(id: string): Observable<Driver> {
        return this.api.get(this.serviceName, '', id).pipe(
            map((item: any) => this.adapter.adapt(item) )
        );
    }

    getCurrent(): Observable<Driver> {

        return this.api.get(this.serviceName, 'current').pipe(
            map((resp: any) => this.adapter.adapt(resp))
        );
    }

    update(id: string, data: Partial<Driver>): Observable<Driver> {
        
        // Afegim els links als recursos
        const patchData = {
            name: data.name,
            truck: data.truck? this.api.urlToResource(TruckService.serviceName, data.truck.id) : null
        }

        return this.api.patch(this.serviceName, id, '', patchData).pipe(
            map(resp => this.adapter.adapt(resp))
        );
    }


    delete(id: string) {
        return this.api.delete(this.serviceName, id).pipe(
            map((resp:any) => true));
    }
}

