import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { RouteAssignedState } from '../../../route-assigned.state';

@Component({
  selector: 'driver-route-assigned-camera-preview',
  templateUrl: './route-assigned-camera-preview.component.html',
  styleUrls: ['./route-assigned-camera-preview.component.scss']
})
export class RouteAssignedCameraPreviewComponent implements OnInit, AfterViewInit {
  
  @Input() stopVideoOnCaputre: boolean = true;
  @Output() capture: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  @ViewChild('video', { static: true }) video: ElementRef;
  //@ViewChild('canvas', { static: true }) canvas: ElementRef;

  videoWidth = 0;
  videoHeight = 0;
  constraints = {
      video: {
          facingMode: "environment",
          // width: { ideal: 4096 },
          // height: { ideal: 2160 }
      }
  };

  //snapshot: any = null;

  constructor(
      //private state: RouteAssignedState,
      private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      this.startCamera();
  }

  onCapture() {

      const canvas = document.createElement("canvas");
      canvas.width = this.videoWidth;
      canvas.height = this.videoHeight;
      canvas.getContext('2d').drawImage(this.video.nativeElement, 0, 0);
      //this.snapshot = canvas.toDataURL("image/png");
      //console.log(this.snapshot);
      
      if(this.stopVideoOnCaputre) this.stopVideo();
      
      const snapshot = canvas.toDataURL("image/png");
      //console.log(snapshot);
      this.capture.emit(snapshot);

  }

  onCancel() {

      this.stopVideo();
      this.cancel.emit();
      //this.state.activeCamera = false;
  }

  private startCamera() {

      if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
          navigator.mediaDevices.getUserMedia(this.constraints)
              .then( this.attachVideo.bind(this) )
              .catch( this.handleError );
      }
      else {
          alert('Sorry, camera not available.');
          this.cancel.emit();
      }
  }

  private attachVideo(stream: MediaStream) {
      this.renderer.setProperty(this.video.nativeElement, 'srcObject', stream);
      this.renderer.listen(this.video.nativeElement, 'play', (event) => {
          this.videoHeight = this.video.nativeElement.videoHeight;
          this.videoWidth = this.video.nativeElement.videoWidth;
      });
  }

  private stopVideo() {
      const stream: MediaStream = this.video.nativeElement.srcObject;
      const tracks: MediaStreamTrack[] = stream.getTracks();

      tracks.forEach((track) => track.stop());

      this.video.nativeElement.srcObject = null;
  }


  private handleError(error) {
      console.log('Error: ', error);
      this.cancel.emit();
  }


}
