<div class="container">
    <driver-route-assigned-camera-preview
        *ngIf="!snapshot"
        (capture)="onCapture($event)"
        (cancel)="onCancelCapture()">
    </driver-route-assigned-camera-preview>

    <driver-route-assigned-camera-snapshot
        *ngIf="snapshot"
        [snapshot]="snapshot"
        (back)="onBackSnapshot()"
        (send)="onSendSnapshot()" >
    </driver-route-assigned-camera-snapshot>
</div>