import { Injectable } from "@angular/core";

import { AdapterInterface } from 'x-modules/lib/core/adapter';

import { LoadOrderLocation, LoadOrderLocationAdapter } from "./load-order-location";
import { LoadOrderBillingInfo, LoadOrderBillingInfoAdapter } from "./load-order-billing-info";
import { Customer, CustomerAdapter } from './customer';
import { Material, MaterialAdapter } from './material';
import { Document, DocumentAdapter } from "./document";
import { LoadOrderPriceInfo, LoadOrderPriceInfoAdapter } from './load-order-price-info';
import { LOAD_ORDER_STATUS, LoadOrderStatus, LOAD_ORDER_STATUS_DESC } from "./_types/load-order-status.type";
import { PRICE_TYPE } from "./_types/price-type.type";
import { formatNumber } from "@angular/common";

export class LoadOrder {

    constructor(
        public number: number = 0,
        public date: string  = new Date().toISOString().substring(0,10),
        public distance: number = 0,
        public status: LoadOrderStatus = LOAD_ORDER_STATUS.GENERATED,
        public notes: string  = "",
        public trailerPlate: string = "",
        public material: Material = null,
        public customer: Customer = null,
        public fromLocation: LoadOrderLocation = new LoadOrderLocation(),
        public toLocation: LoadOrderLocation = new LoadOrderLocation(),
        public billingInfo: LoadOrderBillingInfo = new LoadOrderBillingInfo(),
        public priceInfo: LoadOrderPriceInfo = new LoadOrderPriceInfo(),
        public loadOrderDocument: Document = null,
        public id?: string
    
    ) {}

    get statusDesc() {
        return LOAD_ORDER_STATUS_DESC[this.status];
    }

    get units() {

        if(!(this.priceInfo instanceof LoadOrderPriceInfo)) return 0;

        switch(this.priceInfo.priceType) {

            case PRICE_TYPE.FROM_WEIGHT_X_TONE_PRICE:
                return this.fromLocation.weight;
        
            case PRICE_TYPE.TO_WEIGHT_X_TONE_PRICE:
                return this.toLocation.weight;
            
            case PRICE_TYPE.JOURNEY:
            case PRICE_TYPE.TIME:
                return 1;
            
        
            default:
                return 0;
        }

    }

    get amountToBill() {

        if(!(this.priceInfo instanceof LoadOrderPriceInfo)) return 0;

        const amount = this.priceInfo.calcAmount( this.weightToBill );
        return Math.round((amount + Number.EPSILON) * 100 / 100);

    }

    get weightToBill() {

        if(!(this.priceInfo instanceof LoadOrderPriceInfo)) return 0;

        switch(this.priceInfo.priceType) {

            case PRICE_TYPE.FROM_WEIGHT_X_TONE_PRICE:
                return this.fromLocation.weight;
        
            case PRICE_TYPE.TO_WEIGHT_X_TONE_PRICE:
                return this.toLocation.weight;
        
            default:
                return 0;
        }
      
    }

    get isGenerated() { return this.status === LOAD_ORDER_STATUS.GENERATED; }
    get isAssigned() { return this.status === LOAD_ORDER_STATUS.ASSIGNED; }
    get isParked() { return this.status === LOAD_ORDER_STATUS.PARKED; }
    get isUnloaded() { return this.status === LOAD_ORDER_STATUS.UNLOADED; }
    get isToBill() { return this.status === LOAD_ORDER_STATUS.TOBILL; }
	get isInvoiced() { return this.status === LOAD_ORDER_STATUS.INVOICED; }
    get isCancelled() { return this.status === LOAD_ORDER_STATUS.CANCELLED; }


}

@Injectable({ providedIn: "root" })
export class LoadOrderAdapter implements AdapterInterface<LoadOrder> {

    constructor(
        private materialAdapter: MaterialAdapter,
        private customerAdapter: CustomerAdapter,
        private loadOrderLocationAdapter: LoadOrderLocationAdapter,
        private loadOrderBillingInfoAdapter: LoadOrderBillingInfoAdapter,
        private loadOrderPriceInfoAdapter: LoadOrderPriceInfoAdapter,
        private documentAdapter: DocumentAdapter,
    ) {}

    adapt(item: any): LoadOrder {

        if(!item) return null;

        return new LoadOrder(
            item['number'], 
            item['date'], 
            item['distance'],
            item['status'],
            item['notes'],
            item['trailerPlate'],
            this.materialAdapter.adapt(item['material']),
            this.customerAdapter.adapt(item['customer']),
            this.loadOrderLocationAdapter.adapt(item['fromLocation']),
            this.loadOrderLocationAdapter.adapt(item['toLocation']),
            this.loadOrderBillingInfoAdapter.adapt(item['billingInfo']),
            this.loadOrderPriceInfoAdapter.adapt(item['priceInfo']),
            this.documentAdapter.adapt(item['loadOrderDocument']),
            item['id']
        );
    }

}


