import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'driver-route-assigned-no-route',
  templateUrl: './route-assigned-no-route.component.html',
  styleUrls: ['./route-assigned-no-route.component.scss']
})
export class RouteAssignedNoRouteComponent implements OnInit {

  @Output() OnRefresh = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onRefresh() {
    this.OnRefresh.emit();
  }

}
