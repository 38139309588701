import { Injectable } from '@angular/core';
import { Route } from '@marsol/models/route';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteAssignedState {

  private _route = new BehaviorSubject<Route>(null);
  private _updating = new BehaviorSubject<boolean>(false);
  private _activeCamera = new BehaviorSubject<boolean>(false);

  constructor( ) {}

  get route$(): Observable<Route> { return this._route.asObservable(); }
  get route(): Route { return this._route.getValue(); }
  set route(value: Route) { this._route.next(value); }

  get updating$(): Observable<boolean> { return this._updating.asObservable(); }
  set updating(value: boolean) { this._updating.next( value ); }

  get activeCamera$(): Observable<boolean> { return this._activeCamera.asObservable(); }
  set activeCamera(value: boolean) { this._activeCamera.next( value ); }

}
