import { PipesModule } from './../../../../../admin/src/app/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@driver/shared/shared.module';

import { PanelLayoutComponent } from './panel-layout.component';

import { PanelHeaderComponent } from './_components/header/panel-header.component';
import { PanelFooterComponent } from './_components/footer/panel-footer.component';
import { PanelSidenavMenuComponent } from './_components/sidenav-menu/panel-sidenav-menu.component';


@NgModule({
  declarations: [
    PanelLayoutComponent,
    PanelHeaderComponent, 
    PanelFooterComponent,
    PanelSidenavMenuComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    PipesModule
    //AngularMaterialModule
  ],
  exports: [
    PanelLayoutComponent
  ],
  providers: [],  
})  
export class PanelLayoutModule {}