<div class="container" fxLayout="column">

    <div>
        <video #video class="vid" autoplay></video>
    </div>

    <div class="buttons" fxLayout="row" fxLayoutAlign="center">
        <div fxFlex></div>
        <button mat-fab color="accent" (click)="onCapture()">
            <mat-icon>camera</mat-icon>
        </button>
        <div fxFlex fxLayoutAlign="end">
            <button mat-fab color="primary" (click)="onCancel()">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
</div>