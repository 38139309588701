import { NgModule } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';


@NgModule({
  exports: [
    // Forms
    MatFormFieldModule, MatButtonModule, MatInputModule,

    // Altres
    MatCardModule, MatSnackBarModule, MatSidenavModule, MatIconModule, MatToolbarModule, MatListModule
  ]
})
export class AngularMaterialModule { }
