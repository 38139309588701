import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Address, AddressAdapter } from './address';

export class RouteCostInfo {

    constructor(
        public vatId: string = '',
        public name: string = '',
        public address: Address = new Address(),
        public discount: number = 0,
        public weight: number = 0,
        public amount: number = 0,
        public id?: string
    ) {}

}

@Injectable({ providedIn: "root" })
export class RouteCostInfoAdapter implements AdapterInterface<RouteCostInfo> {

    constructor(
        private addressAdapter: AddressAdapter
    ) {}

    adapt(item: any): RouteCostInfo {

        if(!item) return null;

        return new RouteCostInfo(
            item['vatId'], 
            item['name'],
            this.addressAdapter.adapt(item['address']), 
            item['discount'],
            item['weight'],
            item['amount'],
            item['id']
        );
    }

}