import { Injectable } from '@angular/core';
import { AdapterInterface } from 'x-modules/lib/core/adapter';
import { Address, AddressAdapter } from './address';

export class CustomerBillingInfo {

    constructor(
        public vatId: string = '',
        public name: string = '',
        public address: Address = new Address(),
        public id?: string
    ) {}

}

@Injectable({ providedIn: "root" })
export class CustomerBillingInfoAdapter implements AdapterInterface<CustomerBillingInfo> {

    constructor(
        private addressAdapter: AddressAdapter
    ) {}

    adapt(item: any): CustomerBillingInfo {

        if(!item) return null;

        return new CustomerBillingInfo(
            item['vatId'], 
            item['name'], 
            this.addressAdapter.adapt(item['address']), 
            item['id']
        );
    }

}
